// This file is part of Indico.UN.
// Copyright (C) 2019 - 2024 United Nations. All rights reserved.

(function(global) {
  var superHandleAjaxError = global.handleAjaxError
  global.handleAjaxError = function _handleAjaxError(data) {
    if (data.status === 403) {
        var err = data.responseJSON.error;
        var popup = new AlertPopup(err.title, err.message);
        popup.open();
        return true;
    } else {
      return superHandleAjaxError(data);
    }
  };
})(window);
