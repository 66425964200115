// This file is part of Indico.UN.
// Copyright (C) 2019 - 2024 United Nations. All rights reserved.

import locationDataListURL from 'indico-url:plugin_un.location_data';

import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {TextArea} from 'semantic-ui-react';

import {SearchDropdown} from 'indico/react/components/WTFSearchDropdown';
import {Translate} from '../../i18n';
import {indicoAxios, handleAxiosError} from 'indico/utils/axios';

export async function fetchLocationData(param) {
  let response;
  try {
    response = await indicoAxios.get(locationDataListURL(param));
  } catch (e) {
    handleAxiosError(e);
    return {error: true, data: null};
  }
  return {error: false, data: response.data};
}

/**
 * Provides a collection of fields to select a location.
 * The displayed fields are `country`, `city`, `venue/location`,
 * `room` and an optional `address`.
 * The widget also uses information from surrounding fields,
 * namely `inherit_location` field to determine when to be disabled
 * or not. And the `arrangement_type` field which is set to disabled
 * when the location is inherited.
 */
export function LocationWidgetComponent({
  data: initialData,
  inheritanceAllowed,
  isInherited,
  editAddress,
  isEventCreation,
  initialValues,
  choices,
  parent,
  onChange: onChangeFromProps,
}) {
  const [data, setData] = useState(initialData);
  const {countries, cities, locations, rooms} = choices;
  const [inheriting, setInheriting] = useState(inheritanceAllowed ? isInherited : false);
  const hasLocationOrRoom = initialValues.location !== null || initialValues.room !== null;
  const [country, setCountry] = useState(initialValues.country);
  const [city, setCity] = useState({
    value: initialValues.city,
    disabled: !hasLocationOrRoom,
    choices: cities,
  });
  const [location, setLocation] = useState({
    value: initialValues.location,
    disabled: !hasLocationOrRoom,
    choices: locations,
  });
  const [room, setRoom] = useState({
    value: initialValues.room,
    disabled: !hasLocationOrRoom,
    choices: rooms,
  });
  const [address, setAddress] = useState(initialValues.address);

  const updateLocationData = (newData = {venue_id: '', room_id: ''}) => {
    setData({...data, ...newData});
    onChangeFromProps({...data, ...newData});
  };

  const clearFields = (targets = {city: true, location: true, room: true}) => {
    if (targets.room) {
      setRoom({value: null, disabled: true, choices: [{id: '', name: ''}]});
    }
    if (targets.city) {
      setCity({value: null, disabled: true, choices: [{id: '', name: ''}]});
    }
    if (targets.location) {
      setLocation({value: null, disabled: true, choices: [{id: '', name: ''}]});
    }
  };

  const handleOnChangeCountry = async val => {
    setCountry([{id: val}]);
    clearFields();
    const {error, data: cityData} = await fetchLocationData({country_id: val});
    if (!error) {
      setCity({value: null, disabled: false, choices: cityData});
      updateLocationData();
    }
  };

  const handleOnChangeCity = async val => {
    setCity({...city, value: [{id: val}]});
    clearFields({location: true, room: true});
    const {error, data: locationData} = await fetchLocationData({city_id: val});
    if (!error) {
      setLocation({...location, disabled: false, choices: locationData});
      updateLocationData();
    }
  };

  const handleOnChangeLocation = async val => {
    setLocation({...location, value: [{id: val}]});
    clearFields({room: true});
    const {error, data: roomData} = await fetchLocationData({location_id: val});
    if (!error) {
      setRoom(prev => ({...prev, disabled: false, choices: roomData}));
      updateLocationData({venue_id: val, room_id: ''});
    }
  };

  const handleOnChangeRoom = async val => {
    if (!isNaN(val) && !isNaN(parseFloat(val))) {
      setRoom({...room, value: [{id: val}]});
    } else {
      setRoom({...room, choices: [...room.choices, {id: val, name: val}], value: [{id: val}]});
    }
    updateLocationData({room_id: val});
  };

  const handleOnChangeAddress = evt => {
    const val = evt.target.value;
    setAddress(val);
    updateLocationData({address: val});
  };

  useEffect(() => {
    const inheritLocation = document.getElementById('inherit_location');
    if (inheritanceAllowed && inheritLocation) {
      const description = document.createElement('div');
      const text = Translate.string(
        'Use arrangement type, location, & virtual details from: {title} ({type})',
        {title: parent.title, type: parent.type}
      );
      description.classList.add('form-field-description');
      description.textContent = text;
      inheritLocation.parentElement.append(description);

      const inPerson = isEventCreation
        ? document.getElementById('event-creation-arrangement_type-0')
        : document.getElementById('arrangement_type-0');
      const virtual = isEventCreation
        ? document.getElementById('event-creation-arrangement_type-1')
        : document.getElementById('arrangement_type-1');
      const hybrid = isEventCreation
        ? document.getElementById('event-creation-arrangement_type-2')
        : document.getElementById('arrangement_type-2');
      const virtualDetails = document.getElementById('virtual_details');
      inPerson.disabled = inheriting;
      virtual.disabled = inheriting;
      hybrid.disabled = inheriting;
      virtualDetails.disabled = inheriting;

      const handleInheritanceChange = evt => {
        if (inheritLocation.checked) {
          setInheriting(true);
          updateLocationData({inheriting: true});
          inPerson.disabled = true;
          virtual.disabled = true;
          hybrid.disabled = true;
          virtualDetails.disabled = true;
        } else {
          setInheriting(false);
          updateLocationData({inheriting: false});
          inPerson.disabled = false;
          virtual.disabled = false;
          hybrid.disabled = false;
          virtualDetails.disabled = false;
        }
      };
      inheritLocation.addEventListener('change', handleInheritanceChange);
      return () => {
        inheritLocation.removeEventListener('change', handleInheritanceChange);
      };
    }
  }, []);

  return (
    <>
      <SearchDropdown
        options={countries}
        defaultValue={country ? country : []}
        onChange={handleOnChangeCountry}
        dropdownProps={{
          required: true,
          placeholder: Translate.string('Select a country'),
          disabled: inheriting,
        }}
      />
      <div style={{marginBottom: '5px'}} />
      <SearchDropdown
        options={city.choices}
        defaultValue={city.value ? city.value : []}
        onChange={handleOnChangeCity}
        dropdownProps={{
          required: true,
          placeholder: Translate.string('Select a city'),
          disabled: city.disabled || inheriting,
        }}
      />
      <div style={{marginBottom: '5px'}} />
      <SearchDropdown
        options={location.choices}
        defaultValue={location.value ? location.value : []}
        onChange={handleOnChangeLocation}
        dropdownProps={{
          required: true,
          placeholder: Translate.string('Select a venue'),
          disabled: location.disabled || inheriting,
        }}
      />
      <div style={{marginBottom: '5px'}} />
      <SearchDropdown
        options={room.choices}
        defaultValue={room.value ? room.value : []}
        onChange={handleOnChangeRoom}
        allowAdditions
        dropdownProps={{
          required: true,
          placeholder: Translate.string('Select a room'),
          disabled: room.disabled || inheriting,
        }}
      />
      <div style={{marginBottom: '5px'}} />
      {editAddress && (
        <fieldset disabled={inheriting}>
          <TextArea
            defaultValue={address}
            onChange={handleOnChangeAddress}
            placeholder={Translate.string('Additional address')}
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              minHeight: 30,
              width: '100%',
            }}
          />
        </fieldset>
      )}
    </>
  );
}

LocationWidgetComponent.propTypes = {
  /** The initial data of the input field */
  data: PropTypes.object.isRequired,
  /** Whether the location data is allowed to be inherited from the parent */
  inheritanceAllowed: PropTypes.bool.isRequired,
  /** Whether the location data is currently being inherited from the parent */
  isInherited: PropTypes.bool.isRequired,
  /** If the widget is being used at the event creation form */
  isEventCreation: PropTypes.bool.isRequired,
  /** If free text address field is allowed */
  editAddress: PropTypes.bool.isRequired,
  /** Initial values for the fields */
  initialValues: PropTypes.shape({
    country: PropTypes.array,
    city: PropTypes.array,
    location: PropTypes.array,
    room: PropTypes.array,
    address: PropTypes.string,
  }).isRequired,
  /** Initial choices for the fields */
  choices: PropTypes.shape({
    countries: PropTypes.array,
    cities: PropTypes.array,
    locations: PropTypes.array,
    rooms: PropTypes.array,
  }).isRequired,
  /** The parent object info */
  parent: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  /** Called when the value changes with the new value as the first argument */
  onChange: PropTypes.func.isRequired,
};

/**
 * WTForms wrapper for LocationWidgetComponent
 */
export default function WTFLocationWidget({fieldId, ...rest}) {
  const field = useMemo(() => document.getElementById(`${fieldId}`), [fieldId]);
  const onChange = value => {
    field.value = JSON.stringify(value);
    field.dispatchEvent(new Event('change', {bubbles: true}));
  };
  // Add an icon with info to the location field label
  const fieldLabel = document.querySelectorAll(`label[for=${fieldId}]`)[0];
  if (fieldLabel) {
    fieldLabel.innerHTML += ' ';
    const info = document.createElement('i');
    const infoMessage = Translate.string(`
        Only countries and cities for which a venue has been defined on Indico.UN system
        are included in the list. Please contact Indico.UN user support if your venue is not listed.
      `);
    info.classList.add('icon', 'icon-info');
    info.title = infoMessage;
    fieldLabel.append(info);
  }
  return <LocationWidgetComponent onChange={onChange} {...rest} />;
}

WTFLocationWidget.propTypes = {
  fieldId: PropTypes.string.isRequired,
};
