// This file is part of Indico.UN.
// Copyright (C) 2019 - 2024 United Nations. All rights reserved.

(function(global){
  /**
   * Widget to create a server-side Datatable.js widget
   * @param tableName  Name of the table
   * @param columns  Column information array of dicts
   * @param options  Table options, necessary to configure datatables widget
   * @param params  Table saved state, if exists, table columns will be taken from here
   * @param widgetOptions  To enable features of the widget. The possible options are:
   *        ColumnFilters: To enable search by column in the table
   *        ColumnVisibility: How many columns show display by default
   *        SelectAll: Makes the first column of the table a selectable checkbox with a SelectAll option in the header
   * @param ajaxUrl  Endpoint to send the requests of the table
   * @param savePrefsUrl  Endpoint to save the current table state in the server
   * @return table  The table API to be used in the template
  */
  global.setupDataTable = function setupDataTable(tableName, columns, options, params, widgetOptions, ajaxUrl, savePrefsUrl){
    var loading;
    var tableConfig = params || {};
    var regex = tableConfig.regex || false;
    var widgetOptions = widgetOptions || {};
    var saveUrl = savePrefsUrl;
    var tname = tableName;
    var table;
    var loaded;
    var searched = false;

    // Pre-Init
    preInitFunctions();
    initOptions(options, ajaxUrl);

    // Init
    // Call any required init function
    table = $(`#${tname}`).dataTable(options).api();
    table.getTableConfig = getTableConfig
    table.applyRegex = applyRegex
    table.selectAllAcrossPages = selectAllAcrossPages
    table.selectAcrossPages = selectAcrossPages
    table.clearSelected = clearSelected
    table.resetGeneralFilter = resetGeneralFilter
    table.resetColumnFilters = resetColumnFilters
    table.allRowsSelected = false
    table.selectedIds = []
    table.deselectedIds = []

    // Post-Init
    postInitFunctions();

    return table;

    function preInitFunctions(){
      if (widgetOptions.columnFilters){
        initFilterFields();
      }
    }

    function selectAllAcrossPages() {
      let pageInfo = table.page.info();
      let numberOfRows = pageInfo.recordsDisplay - table.deselectedIds.length
      let selectInfo = $(`#${tname}_info`).find('.select-info').children();
      selectInfo[0].innerHTML = `All ${numberOfRows} entries are selected`
      selectInfo[1].innerHTML = `<a onclick="{table.clearSelected()}"
                                    class="datatables-select-all">
                                    ${$t.gettext("Clear selected?")}
                                 </a>`;
      table.allRowsSelected = true
    }
    function clearSelected() {
      table.allRowsSelected = false
      table.selectedIds = []
      table.deselectedIds = []
      let selectInfo = $(`#${tname}_info`).find('.select-info').children();
      selectInfo[0].innerHTML = ''
      selectInfo[1].innerHTML = ''
      table.rows({page: 'current'}).deselect();
      $(`#${tname}-select-rows`).prop('checked', false);
    }

    function selectAcrossPages(drawCallback=false) {
      let numberOfRows = 0
      const pageInfo = table.page.info();
      if (table.allRowsSelected) {
        numberOfRows = pageInfo.recordsDisplay - table.deselectedIds.length
      }
      else {
        numberOfRows = table.selectedIds.length
      }
      if (!numberOfRows) {
        return
      }
      let $infoBar = $(`#${tname}_info`)
      let selectInfo = $infoBar.find('.select-info').children();
      if (!selectInfo.length) {
        let selectInfoSpan = $('<span>', {class: 'select-info'});
        let selectItem1 = $('<span>', {class: 'select-item'});
        let selectItem2 = $('<span>', {class: 'select-item'});
        selectInfoSpan.append(selectItem1, selectItem2)
        $infoBar.append(selectInfoSpan)
        selectInfo = $infoBar.find('.select-info').children()
      }
      if (numberOfRows === 1) {
        selectInfo[0].innerHTML = `${numberOfRows} entry is selected.`
      }
      else {
        selectInfo[0].innerHTML = `${numberOfRows} entries are selected.`
      }
      const checked = $(`#${tname}-select-rows`).is(':checked');
      if (checked) {
        const numberOfRowsOnCurrentPage = pageInfo.end - pageInfo.start;
        const numberOfRows = pageInfo.recordsDisplay;
        if (numberOfRows === numberOfRowsOnCurrentPage) {
          return;
        }
        let linkMessage = `Select all ${numberOfRows} entries?`;
        if (pageInfo.recordsTotal !== pageInfo.recordsDisplay) {
          linkMessage = `Select ${numberOfRows} filtered entries?`;
        }
        selectInfo[1].innerHTML = `<a onclick="{table.selectAllAcrossPages()}"
                                      class="datatables-select-all">
                                      ${linkMessage}
                                   </a>`;
      }
      else {
        selectInfo[1].innerHTML = `<a onclick="{table.clearSelected()}"
                                    class="datatables-select-all">
                                    ${$t.gettext("Clear selected?")}
                                   </a>`;
      }
      if (drawCallback && table.selectedIds.length) {
        table.selectedIds.forEach((Id) => {
          table.rows().every(function (rowIdx, tableLoop, rowLoop) {
            let data = this.data();
            if (tname === 'accreditation_table' && data.accreditations.id === Id) {
              table.row(rowIdx).select();
            }
            else if (tname === 'reglist' && data.registrations.id === Id) {
              table.row(rowIdx).select();
            }
          })
        });
      }
    }

    function initFilterFields() {
      if (widgetOptions.columnFilters){
        // Generate filter fields before table initializes to avoid sorting bug:
        // https://datatables.net/forums/discussion/64622/how-to-clone-header-below-it-without-the-sorting-functionality-to-filter-data
        $(`#${tname} thead tr`).clone(true).appendTo(`#${tname} thead `);
        $(`#${tname} thead tr:eq(1) th`).each(function(i) {
          const snakeCase = $(this).text().replace(' ', '_').toLowerCase()
          const title = $(this).text().trim();
          let searchValue = '';
          if (tableConfig.columns) {
            tableConfig.columns.forEach(column => {
              if (column.name === $(this).text()){
                searchValue = column.search.value;
                if (!searched && searchValue !== ''){
                  searched = true;
                }
              }
            });
          }
          if (title === '') {
            $(this).html(`
              <div class="group i-selection">
                <input type="checkbox" id="${tname}-regex-box" onclick="${tname}_table.applyRegex()"></input>
                <label for="${tname}-regex-box" class="i-button" style="padding: 0 0.5rem;"
                       title="${$t.gettext("Regex filtering")}">.*</label>
              </div>`
            );
            $(`#${tname}-regex-box`).prop('checked', regex);
          } else if (title.endsWith('date') || title.endsWith('Date') ){
            $(this).html(`<input type="text" class="filter-field dateField" name="${snakeCase}"
                           id="col-${i}" placeholder="yyyy/mm/dd" value=${searchValue}>`);
          } else {
            $(this).html(`<input type="text" class="filter-field" size="12" name="${snakeCase}"
                           id="col-${i}" value=${searchValue}>`);
          }
          // Listener to make global search trigger only after pressing Enter
          $('input', this).on('keyup change', function (evt) {
            let index;
            if (options.colReorder) {
              index = table.colReorder.order().indexOf(i);
            } else {
              index = i;
            }
            if ((this.value === "") && (table.column(index).search() !== '')) {
              table.column(index).search('').draw();
            } else if ((evt.keyCode === 13) && (table.column(index).search() !== this.value)) {
              evt.stopPropagation();
              table.column(index).search(this.value).draw();
            }
          });
        });
      }
      if (widgetOptions.selectAll){
        $('select', this).on('change', function (evt) {
          if (options.colReorder) {
            index = table.colReorder.order().indexOf(i);
          }
          else {
            index = i;
          }
          if (table.column(index).search() !== this.value) {
            table.column(index).search(this.value).draw();
          }
        });
      }
    }

    function getAjax(url){
      return {
        url: url,
        type: 'POST',
        contentType: 'application/json',
        data: function(d) {
          d.to = $('#to').val();
          d.from = $('#from').val();
          d.year = $('#year').val();
          d.input1 = $('#input1').val();
          d.input2 = $('#input2').val();
          d.checkbox_array = $(`#${tname}_checkbox_array`).val();
          d.select_dropdown = $(`#${tname}_select_dropdown`).val();
          d.regex = $(`#${tname}-regex-box`).is(':checked');
          return JSON.stringify(d);
        }
      }
    };

    function initOptions(options, ajaxUrl){
      options.ajax = options.ajax || getAjax(ajaxUrl);
      options.colReorder = options.colReorder || {fixedColumnsLeft: 1, realtime: false};
      options.columns = tableConfig.columns ? tableConfig.columns : columns;
      options.columnDefs = options.columnDefs || getColumnDefs();
      options.dom = options.dom || 't';
      options.lengthMenu = options.lengthMenu || [10, 20, 50, 100];
      options.pageLength = options.pageLength || 20;
      options.processing = options.processing || false;
      options.serverSide = options.serverSide || true;
      options.scrollCollapse = options.scrollCollapse || true
      options.scrollX = options.scrollX || true;
      options.scrollY = options.scrollY || '80vh'
      options.order = (tableConfig.order ? [Object.values(tableConfig.order[0])] : [[1, 'desc']]) || options.order
      options.orderCellsTop = options.orderCellsTop || true;
      options.ordering = options.ordering || true;
      // XXX: Disabled since it breaks column visibility
      // options.stateSave = options.stateSave || true;
      options.language = {
        'loadingRecords': '&nbsp;',
        'search': '<i class="icon-search"></i>',
        'processing': `${$t.gettext("Loading...")} <i class="icon-spinner"></i>`,
      };
      options.preDrawCallback = function (settings) {
        if (loading === undefined) {
          loading = IndicoUI.Dialogs.Util.progress($t.gettext("Loading..."));
        }
      }
      options.drawCallback = function() {
        if (table.selectedIds.length && !table.allRowsSelected) {
          setTimeout(() => table.selectAcrossPages( true), 500);
        }
        if (table.allRowsSelected) {
            table.rows({page: 'current'}).select();
            setTimeout(() => table.selectAllAcrossPages(), 200);
        };
        if (loaded && saveUrl) {
          saveTableState();
        }
        loading();
        loading = undefined
      };
      options.initComplete = function() {
        if (options.colReorder) {
          table.on('column-reorder.dt', function(e, settings, details) {
            table.draw(false);
          });
        }
        if (widgetOptions.selectAll) {
          if (tableConfig.checkbox_array) {
            let array = tableConfig.checkbox_array.split(',');
            $(`.${tname}-check-input`).each(function(){
              if (array.includes($(this).attr('id'))) {
                $(this).prop('checked', true);
              }
            });
            array = array.toString();
            $(`#${tname}_checkbox_array`).val(array);
          }
        }
        if (widgetOptions.columnFilters) {
          let $filterSwitch = $(`#${tname}-filter-switch`)[0]
          let $filtersRow = $(`#${tname}_wrapper tr:eq(1) th`)
          let $DTWrapper = $(`#${tname}_wrapper`)
          if (tableConfig.filter_switch && !$filterSwitch.checked) {
            $filterSwitch.checked = true;
            $filtersRow.show();
            $DTWrapper.resize();
          } else if (!tableConfig.filter_switch){
            $filterSwitch.checked = false;
            $filtersRow.hide();
          }
          if (!jQuery.isEmptyObject(tableConfig)){
            table.page(tableConfig.page);
            table.page.len(tableConfig.length);
            table.search(tableConfig.search.value);
            if (searched){
              table.columns().eq(0).each(function(i) {
                let colSearch = tableConfig.columns[i].search.value;
                if (colSearch) {
                  table.column(i).search(colSearch);
              }
            });
            }
          }
        }
        // XXX: This is necessary to set the selected page in the pagination menu.
        if (tableConfig.page !== 0) {
          table.draw(false);
        }
        loaded = true;
      };
    }

    function postInitFunctions(){
      if (widgetOptions.selectAll){
        selectAll();
      }
      if (widgetOptions.columnFilters){
        filterSwitch();
      }
      if (widgetOptions.columnVisibility){
        columnVisibility(widgetOptions.columnVisibility);
      }
      columnSearch();
    }

    function saveTableState() {
      // Store user settings via AJAX
      const tConfig = getTableConfig();
      $.ajax({
        url: saveUrl,
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(tConfig),
        error: handleAjaxError,
        success: function(response) {
          if (response && response.success) {
            if (response.flashed_messages) {
              $('#flashed-messages').html(response.flashed_messages);
            }
          }
        },
      });
    }

    function getTableConfig() {
      const tConfig = JSON.parse(table.ajax.params())
      // Add column visibility
      let visible_columns = []
      tConfig.columns.forEach(function (column, i){
        visible_columns.push(table.column(i).visible());
      })
      tConfig.visible_columns = visible_columns;
      tConfig.page = table.page();
      tConfig.deselected = table.deselectedIds
      if (widgetOptions.columnFilters){
        // Add Filter switch config
        tConfig.filter_switch = $(`#${tname}-filter-switch`).is(':checked');
      }
      if (widgetOptions.columnFilters){
        tConfig.column_filters = tableConfig.column_filters;
      }
      tConfig.regex = $(`#${tname}-regex-box`).is(':checked');
      return tConfig;
    }

    function getColumnDefs() {
      if (tableName === 'reglist'){
        return getReglistColumnDefs();
      } else if (tableName === 'accreditation_table'){
        return getAccreditationListColumnDefs();
      } else if (tableName === 'accreditations'){
        return getAccreditationsColumnDefs();
      } else if (tableName === 'logs'){
        return getLogsDefs();
      } else {
        return {};
      }
    }

    function findColumnIndex(data) {
      // Using tableConfig because table has not been initialized
      // Used to find column index for columnDefs
      if (!tableConfig.columns) {
        return;
      }
      let index = 0;
      tableConfig.columns.forEach(function(column, i) {
        delete column.orderable
        if (column.data === data) {
          index = i;
        }
      });
      return index;
    }

    function getReglistColumnDefs() {
      let statusCss = {
        [$t.gettext("Approved")]: "green check",
        [$t.gettext("Pending")]: "yellow clock",
        [$t.gettext("Rejected")]: "red x",
        [$t.gettext("Withdrawn")]: "violet undo",
      };
      let title = $t.gettext('Detailed view');
      let colDefs = [
        // Array to disable sorting columns
        {
          'targets': [0],
          'orderable': false,
          'className': 'select-checkbox',
        },
        // Array to define custom parameters and css to certain columns
        {
          'targets': 1,
          'data': 'first_name',
          'render': function(data, type, full, meta) {
            return '<a href="/event/' + full["registrations"]["event_id"] + '/manage/' + 'registration/' +
                   full["registrations"]["registration_form_id"] + '/registrations/' + full["registrations"]["id"] +
                   `" title="${title}" target="blank">${data}</a>`;
          }
        },
        {
          'targets': 2,
          'data': 'last_name',
          'render': function(data, type, full, meta) {
            return '<a href="/event/' + full["registrations"]["event_id"] + '/manage/' + 'registration/' +
                   full["registrations"]["registration_form_id"] + '/registrations/' + full["registrations"]["id"] +
                  `" title="${title}" target="blank">${data}</a>`;
          },
        },
        {
          'targets': findColumnIndex('registrations.state') || 5,
          'data': 'state',
          'render': function(data, type, full, meta) {
            let status_class = 'large ' + statusCss[data] + ' icon';
            return `<i class="${status_class}"></i>${data}`;
          },
        },
        {
          'targets': findColumnIndex('registrations.rank') || 11,
          'data': 'rank',
          'render': function(data, type, full, meta) {
            return '<input class="rank-field" min="0" max="999" id="rank" value="' + data + '"' +
                   ' onchange="updateRegListRank(this.value, ' + full["registrations"]["event_id"] + ',' +
                   full["registrations"]["id"] + ", '/event/" + full["registrations"]["event_id"] +
                   '/manage/registration/' + full["registrations"]["registration_form_id"] +
                   "/registrations/saverank'" + ')">'
          },
        },
        {
          'targets': findColumnIndex('registrations.tags') || 22,
          'data': 'tags',
          'orderable': false,
          'render': function(data, type, full, meta) {
            return `<div class="tag-list">${data}</div>`;
          },
        },
        {
          'targets': findColumnIndex('registration.picture') || 12,
          'orderable': false,
        },
      ];
      return colDefs;
    }

    function getAccreditationListColumnDefs() {
      const status_css = {
        [$t.gettext('Approved')]: 'green check',
        [$t.gettext('New Requests')]: 'attention',
        [$t.gettext('Rejected')]: 'red x',
        [$t.gettext('Withdrawn')]: 'violet undo',
        [$t.gettext('On Hold')]: 'orange exclamation triangle',
        [$t.gettext('Pre-Approved')]: 'pink info',
        [$t.gettext('Renewal Requested')]: 'blue attention'
      }
      const title = $t.gettext('Detailed view');
      return [
        // Array to disable sorting columns
        {
          'targets': [0],
          'className': 'select-checkbox',
          'orderable': false,
        },
        {
          'targets': findColumnIndex('accreditations.first_name') || 2,
          'data': 'first_name',
          'render': function(data, type, full, meta) {
            return `<a href="/accreditation/manage/${full['accreditations']['id']}/detail/"` +
                   ` title="${title}" target="blank">${data}</a>`;
          }
        },
        {
          'targets': findColumnIndex('accreditations.last_name') || 3,
          'data': 'last_name',
          'render': function(data, type, full, meta) {
           return `<a href="/accreditation/manage/${full['accreditations']['id']}/detail/"` +
                  ` title="${title}" target="blank">${data}</a>`;
          }
        },
        {
          'targets': findColumnIndex('accreditations.state') || 6,
          'data': 'state',
          'render': function(data, type, full, meta) {
            let status_class = `large ${status_css[data]} icon`;
            return `<i class="${status_class}"></i> ${data}`;
          }
        },
        {
          'targets': findColumnIndex('accreditations.picture_metadata') || 11,
          'orderable': false,
        },
      ]
    }

    function getAccreditationsColumnDefs() {
      return [
        {
          'targets': [0],
          'orderable': false,
        }
      ]
    }

    function getLogsDefs() {
      const category_css = {
        [$t.gettext('Office')]: 'cog',
        [$t.gettext('DutyStation')]: 'home',
        [$t.gettext('Management')]: 'user secret',
        [$t.gettext('Requests')]: 'id card',
        [$t.gettext('Emails')]: 'envelope',
        [$t.gettext('Group')]: 'users'
      }
      return [
        {
          "targets": 2,
          "data": "summary",
          "render": function(data, type, full, meta) {
            return `<a data-href="/${full["duty_stations"]["name"]}/manage/logs/data/` +
                   `${full["duty_stations_logs"]["id"]}/" title="${$t.gettext('View data')}" data-title='${data}'` +
                   ` data-ajax-dialog class="discreet-link">${data}</a>`;
          }
        },
        {
          "targets": 3,
          "data": "realm",
          "render": function(data, type, full, meta) {
            var category_class = `large ${full["duty_stations_logs"]["kind"]} ${category_css[data]} icon`;
            return `<i class="${category_class}"></i> ${data}`;
          }
        },
      ]
    }

    function selectAll() {
      $(`#${tname}-select-rows`).on('change', function(e) {
        e.preventDefault();
        const checked = $(`#${tname}-select-rows`).is(':checked');
        if (checked) {
          table.rows({page: 'current'}).select();
        } else if (!checked) {
          if (table.allRowsSelected) {
            table.allRowsSelected = false
          }
          table.rows({page: 'current'}).deselect();
        }
      });
      table.on('select', function (e, dt, type, indexes) {
        if (type === 'row') {
          let rows = table.rows(indexes).data().toArray()
          rows.forEach(function (data, rowIndex) {
            if (tname === 'accreditation_table') {
              if (table.deselectedIds.includes(data.accreditations.id)) {
                table.row(rowIndex).deselect();
                return;
              }
              if (!table.selectedIds.includes(data.accreditations.id)) {
                table.selectedIds.push(data.accreditations.id)
              }
            }
            else if (tname === 'reglist') {
              if (!table.selectedIds.includes(data.registrations.id)) {
                table.selectedIds.push(data.registrations.id)
              }
            }
          });
        }
        table.selectAcrossPages()
      });
      table.on('deselect', function(e, dt, type, indexes) {
        if (type === 'row') {
          let rows = table.rows(indexes).data().toArray()
          rows.forEach(function (data) {
            if (tname === 'accreditation_table') {
              if (table.allRowsSelected) {
                $(`#${tname}-select-rows`).prop('checked', false);
                if (!table.deselectedIds.includes(data.accreditations.id)) {
                  table.deselectedIds.push(data.accreditations.id)
                }
              } else if (table.selectedIds.includes(data.accreditations.id)) {
                let indexToRemove = table.selectedIds.indexOf(data.accreditations.id);
                if (indexToRemove !== -1) {
                  table.selectedIds.splice(indexToRemove, 1);
                }
              }
            } else if (tname === 'reglist') {
              if (table.allRowsSelected) {
                $(`#${tname}-select-rows`).prop('checked', false);
                if (!table.deselectedIds.includes(data.registrations.id)) {
                  table.deselectedIds.push(data.registrations.id)
                }
              } else if (table.selectedIds.includes(data.registrations.id)) {
                let indexToRemove = table.selectedIds.indexOf(data.registrations.id);
                if (indexToRemove !== -1) {
                  table.selectedIds.splice(indexToRemove, 1);
                }
              }
            }
          })
        }
        table.selectAcrossPages()
      });
    table.on('page.dt', function () {
      if (!table.allRowsSelected) {
        $(`#${tname}-select-rows`).prop('checked', false);
      }
    });
    }

    function columnSearch() {
      let $searchField = $(`#${tname}_filter input`)
      // Search on Enter key press
      $searchField.attr('placeholder', $t.gettext('Press enter to search'));
      $searchField.unbind();
      $searchField.bind('keyup', function(evt) {
        if (evt.keyCode === 13 && (table.search() !== $searchField.val())) {
          evt.stopPropagation();
          table.search(this.value).draw();
        }
      });
      // Reset the search if the "x" or 'esc' is pressed in the filter box
      $searchField.bind('change', function() {
        if ((this.value === "") && (table.search() !== '')) {
          table.search('').draw();
        }
      });
    }

    function filterSwitch() {
      // Hide the row if switch
      let $filterSwitch = $(`#${tname}-filter-switch`);
      let $filtersRow = $(`#${tname}_wrapper tr:eq(1) th`)
      let $DTWrapper = $(`#${tname}_wrapper`)
      if ($filterSwitch) {
        $filtersRow.hide();
        $filterSwitch.on('change', function (e) {
          e.preventDefault();
          const checked = $filterSwitch.is(':checked');
          if (checked) {
            $filtersRow.show();
            $DTWrapper.resize();
            applyFilters();
          } else {
            resetGeneralFilter();
            $filtersRow.hide();
          }
        });
      }
    }

    function columnVisibility(visibleColumns){
      // Logs table requires different visibility
      const tConfig = tableConfig;
      if (!tConfig.visible_columns) {
        // If there's no tableConfig, show the first X columns
        for (let i=0; i<table.columns()[0].length; i++) {
          if (i<visibleColumns) {
            table.column(i).visible(true);
          } else {
            table.column(i).visible(false);
          }
        }
      } else {
        tConfig.visible_columns.forEach(function (column, i) {
          table.column(i).visible(column);
        });
      }
    }

    function applyFilters(){
      // Apply the filters stored in column_filters to the table
      const column_filters = JSON.parse(localStorage.getItem(getLocalStoreItemName()));
      if (column_filters === null) {
        return
      }
      let $headers = $(`#${tname}_wrapper tr:eq(0) th`);
      let $filtersRow = $(`#${tname}_wrapper tr:eq(1) th`);
      let hasSearchValue = false;
      for (let i=0; i<$headers.length; i++) {
        let column = column_filters.find(x => x.name === $headers[i].textContent)
        if (column && column.search.value ) {
          hasSearchValue = true;
          $filtersRow[i].children[0].value = column.search.value;
          table.column(`${column.name}:name`).search(column.search.value);
        }
      }
      if (hasSearchValue) {
        table.draw()
      } else if (saveUrl) {
        saveTableState()
      }
    }

    function resetColumnFilters(){
      // Resets column-wise filters
      let $filterSwitch = $(`#${tname}-filter-switch`);
      let $filtersRow = $(`#${tname}_wrapper tr:eq(1) th`)
      let $DTWrapper = $(`#${tname}_wrapper`)
      let $filterFields = $('.filter-field').toArray()
      let $searchField = $(`#${tname}_filter input`)
      let $regexButton = $(`#${tname}-regex-box`)
      $filterFields.forEach(function (field) {
        field.value = ''
      });
      $filterSwitch.prop('checked', false);
      $filtersRow.hide();
      $searchField.val('');
      $DTWrapper.resize();
      if ($regexButton.is(':checked')) {
        $regexButton.prop('checked', false);
      }
      table.draw(false);
    }

    function resetGeneralFilter(storeFilters=true){
      // Stores current filters in column_filters and resets general filter
      const tConfig = getTableConfig();
      if (storeFilters) {
        localStorage.setItem(getLocalStoreItemName(), JSON.stringify(tConfig.columns))
      }
      else {
        localStorage.setItem(getLocalStoreItemName(), {})
      }
      tConfig.columns.forEach(function(column, i) {
        table.column(i).search('');
      });
      table.draw(false);
    }

    function getLocalStoreItemName() {
      return `DataTables_${window.location.pathname}${window.Indico.User.id}`
    }

    function applyRegex() {
      let $filtersRow = $(`#${tname}_wrapper tr:eq(1) th`);
      let hasSearchValue = false;
      for (let i=0; i<$filtersRow.length; i++) {
        let column = $filtersRow[i];
        if (column && column.children[0].value) {
          hasSearchValue = true;
          table.column(i).search(column.children[0].value);
        }
      }
      if (hasSearchValue) {
        table.draw();
      } else if (saveUrl) {
        saveTableState();
      }
    }
  }
})(window);
