// This file is part of Indico.UN.
// Copyright (C) 2019 - 2024 United Nations. All rights reserved.

'use strict';
import './index.scss';
import './patches/errors';
import './js/location-widget';
import './js/city-widget';
import './js/datatable-widget';
import './js/util';
import 'yearpicker.js/dist/yearpicker.css';
import Cropper from 'cropperjs';
import yearpicker from 'yearpicker.js';
import {showUserSearch, showGroupSearch} from 'indico/react/components/principals/imperative';
import {showRequestConfirmWithReason} from './react/components/RequestConfirmWithReason';

import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-se';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-colreorder';
import 'datatables.net-select';

// Make Cropper availabe for the accreditation module
window.Cropper = Cropper;

(function(global) {
    window.$t = $T.domain('un');
    // XXX: Fallback to core translations if not present within plugin domain
    //      This is a workaround so that we can use $t in files forked from the core (AngularJS)
    $t.pluginGettext = $t.gettext;
    $t.gettext = function(text) {
        let translatedText =  $t.pluginGettext(text);
        return (translatedText == text) ? $T.gettext(text) : translatedText;
    }
})(window);

function Exception(message) {
    this.message = message;
    this.name = 'Exception';
}

window.yearpicker = yearpicker
window.showUserSearch = showUserSearch
window.showGroupSearch = showGroupSearch
window.showRequestConfirmWithReason = showRequestConfirmWithReason


Exception.prototype.toString = function() {
    return this.name + '(' + this.message + ')';
}

var defaultDataTableOptions = {
    fieldLabels: [], /* field translation labels for header
                        <field_name in the DB> -> <meaningful translated text>
    */
    actions: {}, /*
        action buttons specifications:
        it must be a list of objects with the following attributes:
         - icon : <string> name of css icon-class
         - tooltip : <string> tooltip text for the action icon
         - url where the action links
     */
    cbBeforeDataTables: null, /*
        call-back function to be called before the actual DataTables
        object is called. the callback receives the DataTable configuration
        object.
    */
    showId: false, /*
        if true it shows the ID of the table to the end-user, otherwise
        the ID field will not be shown.
    */
    selectable: false, /*
        If true, the table permit the row multi selection.
    */
    onComplete: function(dt) {} /*
        callback called after the creation of the DataTables object and data rendered
        td: is the DataTable instance
    */
};

/**
 * Generate a DataTable widget with field translations and actions
 * @param selector String: css selector identifies the HTMLElement
 *                             which will become the DataTable
 * @param data [String|Object]: data can be or a URL where to GET data from
 *                              or an object that contains data as it came
 *                              from IndicoQuery.json property
 * @param options [Object]: non-mandatory arguments described above
 *                          in the defaultDataTableOptions variable.
 * @param origOptions [Object]: additional arguments passed directly to
 *                              DataTable object.
 **/

function data_tables(selector, data, options, origOptions) {
    options =  $.extend(true, defaultDataTableOptions, options);

    var field_labels = options.fieldLabels;
    var actions = options.actions;
    var cbBeforeDataTables = cbBeforeDataTables;
    var self = this;

    function initialize(data) {
        if (options.selectable){
            data.fields.unshift(['select','String']);
            data.data.forEach(function(row){
                row.unshift('');
            });
        }
        self.fields = _.object(data.fields);
        var fieldIdx = self.fieldIdx = _.object(data.fields.map(function(field, idx){
            return [field[0], idx];
        }));
        var columns = data.fields.map(function (field, i) {
            if (i < field_labels.length) {
                return {title: field_labels[i]}
            } else {
                return {title: field[0]};
            }
        });
        if (!options.showId) {
            columns.forEach(function(col){
                if (col.title == 'id') {
                    col.visible = false;
                }
            })
        }
        self.data = data.data;
        var data = data.data;

        window.fields = self.fields;
        window.data = data;

        console.log('Looking for special type fixtures');
        var types = Object.values(self.fields);
        var dates = [];
        var booleans = [];
        var datetimes = [];
        var times = [];
        for (var idx in types) {
            if (types[idx] === 'Date') {
                dates.push(Number(idx));
            } else if (types[idx] === 'DateTime') {
                datetimes.push(Number(idx));
            } else if (types[idx] === 'Time') {
                times.push(Number(idx));
            } else if (types[idx] === 'Boolean') {
                booleans.push(Number(idx));
            }
        }
        if (dates.length) {
            console.log(dates.length + ' dates found. Applying conversion');
        }
        if (booleans.length) {
            console.log('{0} boolean columns found.'.format(booleans.length));
        }
        var t = new Date();
        data.forEach(function (row) {
            dates.concat(datetimes).forEach(function (idx) {
                var val = row[idx];
                if (val !== undefined) {
                    row[idx] = new Date(val * 1000);
                }
            });
        });

        console.log('Converted data in {0} milliseconds'.format(new Date() - t));

        var aoColumnDefs = [];
        if (dates.length)
            aoColumnDefs.push({
                aTargets: dates,
                fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
                    if (sData) {
                        nTd.innerText = Util.formatDateTime(sData, IndicoDateTimeFormats.DefaultHourless);
                    } else {
                        nTd.innerText = '';
                    }
                }
            });
        if (datetimes.length)
            aoColumnDefs.push({
                aTargets: datetimes,
                fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
                    if (sData) {
                        nTd.innerText = Util.formatDateTime(sData, IndicoDateTimeFormats.Default);
                    } else {
                        nTd.innerText = '';
                    }
                }
            });
        if (booleans.length)
            aoColumnDefs.push({
                aTargets: booleans,
                fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
                    if (sData) {
                        $(nTd).html('').append($('<b>T</b>'));
                    } else {
                        $(nTd).html('').append($('<i>F</i>'));
                    }
                }
            });

        if (Object.keys(actions).length) {
            var id = fieldIdx.id;
            columns.push({title: 'Actions', sortable: false});
            // adding a column to data
            data.forEach(function(row){
                row.push(null);
            });
            aoColumnDefs.push({
                aTargets: [columns.length - 1],
                fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
                    var data = oData; // data var is used in action.condition
                    var at = [];
                    actions.forEach(function(action){
                        var actionTemplate = "<a class=\"icon {{ icon }}\"\n" +
                        "   data-ajax-dialog\n" +
                        "   data-title=\"{{ label }}\"\n" +
                        "   data-href=\"{{ url }}\"\n" +
                        "   data-confirm-close-unsaved\n" +
                        "   data-reload-after>\n" +
                        "</a>\n";
                        var actionTemplateDisabled = "<span class=\"icon {{ icon }}\"\n" +
                        "   title=\"{{ label }}\"\n" +
                        "></span>\n";
                        if (!action.condition || eval(action.condition)) {
                            actionTemplate = actionTemplate
                            .replace('{{ icon }}', action.icon)
                            .replace('{{ url }}', action.url)
                            .replace('{{ label }}', action.tooltip);
                            at.push(actionTemplate.format(oData[id]));
                        } else {
                            actionTemplateDisabled = actionTemplateDisabled
                            .replace('{{ icon }}', action.icon)
                            .replace('{{ label }}', action.tooltip);
                            at.push(actionTemplateDisabled.format(oData[id]));
                        }
                    });
                    $(nTd).append($('<div>' + at.join('&nbsp;') + '</div>'));
                }
            });
        }

        var confObject = {
            columns: columns,
            data: data,
            aoColumnDefs: aoColumnDefs,
        };

        if (cbBeforeDataTables) {
            cbBeforeDataTables(confObject)
        }

        if (options.selectable) {
            confObject.select = {
                style: 'multi',
                selector: 'td:first-child',
                sortable: false,
            };
            //
            confObject.columns[0] = {
                orderable: false,
                className: 'select-checkbox',
                title: 'select'
            };
        }
        $.extend(confObject, origOptions);
        return tab.DataTable(confObject);
    }

    var tab = $(selector);
    if (!tab.length) {
        throw new Exception('no table found for DataTables')
    }
    if (data.constructor == Object) {
        // This is pure data
        self.tab = initialize(data);
        options.onComplete(this.tab);
    } else {
        // data is an URL and everything is postponed
        window.message = cornerMessage({
            message: 'Loading data ...',
            class: 'highlight',
        });

        $.get(data, {}, function(data, status, xhr){
            $('#corner-message-container').remove();
            if (status === 'success'){
                self.tab = initialize(JSON.parse(data));
                options.onComplete(self.tab);
            }
        });
    }
}

data_tables.prototype.getId = function (row, pk) {
    if (!pk) {
        pk = 'id';
    }
    return row[this.fieldIdx[pk]];
}

data_tables.prototype.getSelectedIds = function(){
    if (!this.tab) {
        return [];
    }
    var self = this;
    return this.tab.rows({selected: true}).toArray()[0].map(function(idx){
        return self.data[idx][self.fieldIdx.id]
    });
};

window.DataTableWrapper = data_tables;

// QR code scanner stuff

(function(global) {
    global.initQRCodeReader = function(url) {
        // See https://stackoverflow.com/a/51502240
        var timeoutHandler = 0;
        var inputString = '';
        $(document).on({'keypress': function(e) {
            if (timeoutHandler) {
                clearTimeout(timeoutHandler);
            }
            if (e.key != 'Enter') {
                inputString += e.key;
            }
            timeoutHandler = setTimeout(function () {
                var tag = e.target.tagName.toLowerCase();
                if (e.key === 'Enter' && tag != 'input' && tag != 'textarea' && inputString != '') {
                    $(e.target).trigger('qrCodeScanned', inputString);
                    inputString = '';
                }
                inputString = '';
            }, 50);
        }});
        $(document).on('qrCodeScanned', function (e, scannedString) {
            let payload = `payload=${scannedString}`;
            $.ajax({
                type: "POST",
                url: url,
                data: payload,
                dataType: "json",
                error: handleAjaxError,
                complete: IndicoUI.Dialogs.Util.progress(),
                success: function (response) {
                    const urls = response.urls;
                    if (urls.length > 1){
                        window.open(urls[0]);
                        location.href = urls[1];
                    } else {
                        location.href = urls[0];
                    }
                }
            });
        });
    }
})(window);
