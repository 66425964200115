// This file is part of Indico.UN.
// Copyright (C) 2019 - 2024 United Nations. All rights reserved.

import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';

import {SearchDropdown} from 'indico/react/components/WTFSearchDropdown';
import {Translate} from '../../i18n';
import {fetchLocationData} from './WTFLocationWidget';

export function CityWidgetComponent({
  city: initialCity,
  country: initialCountry,
  choices,
  onChange: onChangeFromProps,
}) {
  const {countries, cities} = choices;
  const [country, setCountry] = useState(initialCountry);
  const [city, setCity] = useState({
    value: initialCity,
    disabled: !initialCity,
    choices: cities,
  });

  const handleOnChangeCountry = async val => {
    setCountry([{id: val}]);
    setCity({value: null, disabled: true, choices: [{id: '', name: ''}]});
    const {error, data: cityData} = await fetchLocationData({country_id: val, 'unrestricted': true});
    if (!error) {
      setCity({value: null, disabled: false, choices: cityData});
    }
  };

  const handleOnChangeCity = async val => {
    setCity({...city, value: [{id: val}]});
    onChangeFromProps(val);
  };

  return (
    <>
      <SearchDropdown
        options={countries}
        defaultValue={country ? country : []}
        onChange={handleOnChangeCountry}
        dropdownProps={{
          required: true,
          placeholder: Translate.string('Select a country'),
        }}
      />
      <div style={{marginBottom: '5px'}} />
      <SearchDropdown
        options={city.choices}
        defaultValue={city.value ? city.value : []}
        onChange={handleOnChangeCity}
        dropdownProps={{
          required: true,
          placeholder: Translate.string('Select a city'),
          disabled: city.disabled
        }}
      />
    </>
  );
}

CityWidgetComponent.propTypes = {
  /** The initial city */
  city: PropTypes.array.isRequired,
  /** The initial country */
  country: PropTypes.array.isRequired,
  /** Initial choices for the fields */
  choices: PropTypes.shape({
    countries: PropTypes.array,
    cities: PropTypes.array,
  }).isRequired,
  /** Called when the value changes with the new value as the first argument */
  onChange: PropTypes.func.isRequired,
};

/**
 * WTForms wrapper for CityWidgetComponent
 */
export default function WTFCityWidget({fieldId, ...rest}) {
  const field = useMemo(() => document.getElementById(`${fieldId}`), [fieldId]);
  const onChange = value => {
    field.value = value;
    field.dispatchEvent(new Event('change', {bubbles: true}));
  };
  return <CityWidgetComponent onChange={onChange} {...rest} />;
}

WTFCityWidget.propTypes = {
  fieldId: PropTypes.string.isRequired,
};
