// This file is part of Indico.UN.
// Copyright (C) 2019 - 2024 United Nations. All rights reserved.

import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Button, Divider, Label, Modal, TextArea} from 'semantic-ui-react';

import {Translate} from 'indico/react/i18n';
import {injectModal} from 'indico/react/util';

export default function RequestConfirmWithReason({
  header,
  children,
  onConfirm,
  confirmText,
  cancelText,
  onClose,
  size,
  negative,
}) {
  const [open, setOpen] = useState(true);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [reason, setReason] = useState(null);

  const handleConfirmClick = async () => {
    setRequestInProgress(true);
    onConfirm(reason);
    setOpen(false);
  };

  return (
    <Modal
      onClose={onClose}
      size={size}
      closeIcon={false}
      closeOnDimmerClick={!requestInProgress}
      closeOnEscape={!requestInProgress}
      open={open}
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        {children}
        <Divider horizontal/>
        <Label pointing='below'>Reason</Label>
        <TextArea
          onChange={e => setReason(e.target.value)}
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            minHeight: 40,
            width: "100%"
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={handleConfirmClick}
          disabled={(!reason || reason.length < 4) || requestInProgress}
          loading={requestInProgress}
          negative={negative}
          primary={!negative}
        >
          {confirmText}
        </Button>
        <Button onClick={onClose} disabled={requestInProgress}>
          {cancelText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

RequestConfirmWithReason.propTypes = {
  header: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'large', 'fullscreen']),
  negative: PropTypes.bool,
};

RequestConfirmWithReason.defaultProps = {
  children: null,
  confirmText: Translate.string('Ok'),
  cancelText: Translate.string('Cancel'),
  size: 'tiny',
  negative: false,
};

export function showRequestConfirmWithReason(header='', description='') {
  return injectModal(resolve => (
    <RequestConfirmWithReason
      header={header}
      onConfirm={reason => reason ? resolve({'reason': reason, 'cancel': false}) : resolve({
        'reason': null,
        'cancel': false
      })}
      onClose={() => resolve({'reason': null, 'cancel': true})}
    >
      {description}
    </RequestConfirmWithReason>
  ));
}
