// This file is part of Indico.UN.
// Copyright (C) 2019 - 2024 United Nations. All rights reserved.

import React from 'react';
import ReactDOM from 'react-dom';

import WTFCityWidget from '../react/components/WTFCityWidget';

window.setupCityWidget = function setupCityWidget(props) {
  const field = $(`#cityWidget-${props.fieldId}`);
  field.closest('.ui-dialog-content').css('overflow', 'inherit');
  field.closest('.exclusivePopup').css('overflow', 'inherit');

  ReactDOM.render(<WTFCityWidget fieldId={props.fieldId} {...props} />, field[0]);
};
