// This file is part of Indico.UN.
// Copyright (C) 2019 - 2024 United Nations. All rights reserved.

(function(global) {
  'use strict';
  /*
   * Removes URL parameters
   * @param paramsToRemove - param array
   */
  global.removeURLParameters = function removeURLParameters(paramsToRemove) {
    const deleteRegex = new RegExp(paramsToRemove.join('=|') + '=')
    const params = location.search.slice(1).split('&')
    let search = []
    for (let i = 0; i < params.length; i++)
      if (deleteRegex.test(params[i]) === false) search.push(params[i])
    window.history.replaceState({}, document.title, location.pathname + (search.length ? '?' + search.join('&') : '') + location.hash)
  }
})(window);
