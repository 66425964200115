// This file is part of Indico.UN.
// Copyright (C) 2019 - 2024 United Nations. All rights reserved.

import React from 'react';
import ReactDOM from 'react-dom';

import WTFLocationWidget from '../react/components/WTFLocationWidget';

window.setupCustomLocationWidget = function setupCustomLocationWidget(props) {
  const field = $(`#locationWidget-${props.fieldId}`);
  field.closest('.ui-dialog-content').css('overflow', 'inherit');
  field.closest('.exclusivePopup').css('overflow', 'inherit');

  ReactDOM.render(<WTFLocationWidget fieldId={props.fieldId} {...props} />, field[0]);
};
